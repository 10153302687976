/* eslint-disable */
import request from '@/plugins/request'

const prefix = 'lessons'

/**
 *
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const storeLesson = data => {
    const options = {
        method: 'POST',
        url: `${prefix}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const updateLesson = (uuid, data) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const updateLessonOptions = (uuid, { title, description, options }) => {
    const payload = {
        method: 'PATCH',
        url: `${prefix}/${uuid}/options`,
        data: { title, description, options }
    }

    return request(payload)
}

/**
 *
 * @param {String} uuid
 * @returns {AxiosPromise}
 */
export const deleteLesson = uuid => {
    const options = {
        method: 'DELETE',
        url: `${prefix}/${uuid}`
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const sendInviteToOnlineLesson = (uuid, data) => {
    const options = {
        method: 'POST',
        url: `${prefix}/${uuid}/send-invoice`,
        data: data
    }

    return request(options)
}
